<template>
  <vs-row vs-justify="center">       
    <vs-col vs-lg="12" vs-xs="12" vs-sm="12">
      <vs-card>
        <!-- Header -->
          <div class="d-md-flex align-items-center">
              <!--<div>
      <h3 class="card-title">Projects of the Month</h3>
      <h5 class="card-subtitle text-muted font-weight-normal">Overview of Latest Month</h5>
    </div>-->
              <div class="ml-auto">
                  <vs-select class="w-150px" v-model="filter1">
                      <vs-select-item :key="index"
                                      :value="item.value"
                                      :text="item.text"
                                      v-for="(item,index) in filteroptions1" />
                  </vs-select>
              </div>
          </div>
        <StdTable  :key="reload"  :pais="filter1" :listpais="listpais"></StdTable>
      </vs-card>
    </vs-col>  
  </vs-row>
</template>
<script>
// //////////////////////////////////////////
// Import Components Here
// //////////////////////////////////////////    
    
import StdTable from "../survey-table/StadiumsTable.vue"

    import { Surveys } from "../../../services/Arena.js";
    const sv = new Surveys;

// //////////////////////////////////////////
// Export Here
// //////////////////////////////////////////
export default {
  name: "Surveys",
  data: () => ({
    title: "Surveys",
    // Month Table
      filter1: 0,
      reload: false,
      listpais:[],
    filteroptions1: [      
    ]
  }),
  components: {
      StdTable,    
        },
        created() {
            this.setSideBar(false);
            this.filteroptions1 = [];
            this.filteroptions1.push({ text: "Todos os paises", value: 0 });
            sv.GetCountries().then((resp) => {
                this.listpais = resp;
                for (var i = 0; i < resp.length; i++) {
                    var payload = { 'text': resp[i].countryNameOriginal, 'value': resp[i].countryId };
                    this.filteroptions1.push(payload);
                }
            }, (err) => alert(err));
        },
        watch: {
            filter1: function () {
                this.reload = !this.reload
            },
            filterRkType: function () {
                this.reload = !this.reload
            }
        },  
         methods: {
             setSideBar(val) {
            this.$store.commit("TOGGLE_REDUCE_SIDEBAR", val);
          }
        }
};
</script>