<template>
    <div>
        <div v-bar class="vs-scrollable" style="height: 393px;">
            <div>
                
                <vs-table :data="StadiumsList" class="text-nowrap">
                    <template slot="thead">
                        <vs-th>Nome</vs-th>
                        <vs-th>Code</vs-th>
                        <vs-th>City</vs-th>
                        <vs-th>Country</vs-th>
                    </template>

                    <template slot-scope="{data}">                       
                        <vs-tr class="border-top" :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                            <vs-td>                                
                                <div class="d-flex no-block align-items-center">                                    
                                    <div class="mr-2">                                        
                                        <img :src="getflag2(data[indextr].countryId)"
                                             alt="Pais"                                             
                                             width="30" />


                                        <!--
    <img :src="require('@/assets/images/countries/' + data[indextr].countryId + '.png')"
         alt="Pais"
         class="rounded-circle"
         height="30"
         width="30" />

                                            <img :src="require(`/assets/images/countries/${data[indextr].countryId}.png`)"
                                             alt="Pais"
                                        height="30"
                                        width="30" />\



                                        <img :src="getflag(data[indextr].countryId)"
                                             alt="Pais"
                                             height="30"
                                             width="30" />
                                            -->
                                    </div>
                                    <div>
                                        <router-link :to='getRoute(data[indextr].estadioId)'> <h5 class="mb-0 font-16 font-medium">{{ data[indextr].estadioName }}</h5></router-link>
                                    </div>
                                </div>
                            </vs-td>
                            <vs-td>{{ data[indextr].estadioCode }}</vs-td>
                            <vs-td>{{ data[indextr].city }}</vs-td> 
                            <vs-td><span class="font-medium">{{getCountryName(data[indextr].countryId)}}<!-- countryNameOriginal {{ data[indextr].country_name_original }}--></span></vs-td>
                            <!--
                        <vs-td>
    <div class="d-flex align-items-center">
        <vs-tooltip :text="data[indextr].statustext" :color="data[indextr].statuscolor">
            <i class="mdi mdi-checkbox-blank-circle" :class="[data[indextr].status]"></i>
        </vs-tooltip>
    </div>
                            </vs-td>
                                -->
                        </vs-tr>
                    </template>
                </vs-table>
            </div>
        </div>
    </div>
</template>
<script>

    import { Stadiums} from "../../../services/Arena.js";
    const st = new Stadiums;

    export default {
            name: "StadiumsTable",
      data: () => ({
            title: "StadiumsTable",

        // Data for data[indextr]
          StadiumsList: [
        ]
            }),
        props: {
            pais: { type: Number },
            tipork: { type: Number },
            listpais: []
        },
        created() {
            this.$vs.loading();
            st.Get(this.pais).then((resp) => {
                this.StadiumsList = resp;
                this.$store.commit("SET_stadiumList", resp);
                this.$vs.loading.close();
            }, (err) => alert(err));

        },
        computed:{
            getflag: function(id) {
                var s = "";
                s = "@/assets/images/countries/" + id + ".png";
                return s;
            }            
    },
        methods: {
            getflag2(id) {
                var s = "";
                s = `/img/countries/${id}.png`;                               
                return s;
            },
            getRoute: function (id) {
                var s = "";                
                s = `/estadios/det/${id}`;
                return s;
            },
            getCountryName(id) {
                var s = "";
                for (var i = 0; i < this.listpais.length; i++) {
                    if (this.listpais[i].countryId == id)
                        s = this.listpais[i].countryNameOriginal;
                }
                return s;
            }
        }
};
</script>
